import React, { useRef, useLayoutEffect } from 'react';
import { gsap } from 'gsap';
import { ScrollTrigger } from "gsap/ScrollTrigger";

import ImgPrimaveraOne from '../../images/primaveraOne.png';
import ImgPrimaveraTwo from '../../images/primaveraTwo.png';
import ImgAmbientePng from '../../images/ImgAmbiente.png';

import {
    WrapperContainer,
    PrimaveraOne,
    Container,
    WrapperText,
    Text,
    WrapperImgAmbiente,
    ImgAmbiente,
    PrimaveraTwo
} from './ambienteElements';


export const Ambiente = () => {
    const ref = useRef(null);

    useLayoutEffect(() => {
        ScrollTrigger.refresh();
        gsap.registerPlugin(ScrollTrigger);
        gsap.to(".imgOne", {
            x: 0,
            opacity: 1,
            scale: 1.3,
            right: -2,
            scrollTrigger:{
                trigger: "#ambiente",
                start: "top center",
                end: "bottom 400px",
                scrub: true,
                pinSpacing: false,
            }
        })
        return() => {
            gsap.killTweensOf(".imgOne");
        }
    }, [])
    
    useLayoutEffect(() => {
        gsap.registerPlugin(ScrollTrigger);
        gsap.to(".imgTwo", {
            x: 0,
            opacity: 1,
            scale: 1.3,
            left: -5,
            scrollTrigger:{
                trigger: "#ambiente",
                start: "top center",
                end: "bottom 400px",
                scrub: true,
            }
        })
        return() => {
            gsap.killTweensOf(".imgTwo");
        }
    }, [])

  return (
    <WrapperContainer ref={ref} id='ambiente'>
        <PrimaveraOne className='imgOne' src={ImgPrimaveraOne} />
        <Container>
            <WrapperText>
                <Text>
                "Sabor, conforto e elegância em cada momento."
                </Text>
            </WrapperText>
            <WrapperImgAmbiente>
                <ImgAmbiente src={ImgAmbientePng} />
            </WrapperImgAmbiente>
        </Container>
        <PrimaveraTwo className='imgTwo' src={ImgPrimaveraTwo} />
    </WrapperContainer>
  )
}
