import React, { useState } from 'react';
import Sidebar from '../components/Sidebar';
import Navbar from '../components/Navbar';
import HeroSection from '../components/HeroSection';
import HistorySection from '../components/HistorySection';
import GalerySection from '../components/GalerySection';
import { Ambiente } from '../components/Ambiente';
import CulinariaSection from '../components/CulinariaSection';
import CardapioSection from '../components/CardapioSection'
import Footer from '../components/Footer';
// import SafadinhaSection from '../components/SafadinhaSection';
import Slider from '../components/Slider';
import { items } from '../components/Slider/Data';



const Home = () => {
    const [isOpen, SetIsOpen] = useState(false);

    const toggle = () => {
        SetIsOpen(!isOpen);
    };

  return (
    <>
        <Sidebar isOpen={isOpen} toggle={toggle} />
        <Navbar toggle={toggle} />
        <HeroSection />
        <HistorySection />
        <Ambiente />
        <CulinariaSection />
        <Slider images={items}/>
        <CardapioSection />
        <Footer />
    </>

  )
}

export default Home;
