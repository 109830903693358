import React, {useRef, useLayoutEffect} from 'react';
import Img from '../../images/history_photo_01.png';

import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

import {
    HistoryWrapper,
    SchaduleContent,
    TitleHistory,
    HistoryImg1,
    Wrapper1,
    TitleSeparador,
    Parag,
    Wrapper2,
    Parag2
} from './HistoryElements';


const HistorySection = () => {
    const ref = useRef(null);

    useLayoutEffect(() => {
        gsap.registerPlugin(ScrollTrigger);
        gsap.to(".schadule", {
            y: 0,
            opacity: 1,
            scrollTrigger:{
                trigger: ".title",
                start: "top 480px",
                end: "bottom 300px",
                scrub: true,
            }
        })
        return() => {
            gsap.killTweensOf(".schadule");
        }
    }, [])




  return (
    <>
        <HistoryWrapper ref={ref} id='varandas'>
        <SchaduleContent className='schadule'>
            <TitleHistory className='title'>Nossa História</TitleHistory>
            <TitleSeparador>.</TitleSeparador>
            <Wrapper1>
                <HistoryImg1 loading="lazy" src={Img} alt='imagem ambiente do restaurante'/>
                <Parag>Localizado no coração de Bonito, a apenas uma quadra da icônica Praça da Liberdade, o restaurante Varandas mantém viva a tradição de um ponto gastronômico de destaque na cidade. Desde 1992 até aproximadamente 1995, o restaurante foi administrado pela família do atual proprietário. Após esse período, passou a ser de propriedade de outros donos, mas continuou a ser um local reconhecido pela excelência culinária. Em 2016, o restaurante voltou à gestão dos atuais proprietários, já com o nome de Aquário Restaurante. Em 2019, passou por uma reforma completa e, com um novo conceito e ambiente, reabriu suas portas como o restaurante Varandas, reafirmando seu compromisso com a qualidade e a experiência gastronômica na região.</Parag>
            </Wrapper1>

            <Wrapper1>
                <HistoryImg1 loading="lazy" src={Img} alt='imagem ambiente do restaurante'/>
                <Parag>Com uma combinação única de sabores, tradição e inovação, o Varandas é o lugar perfeito para quem busca uma experiência culinária de alto nível, seja para um almoço descontraído ou um jantar especial. Nossa equipe está sempre empenhada em proporcionar momentos inesquecíveis aos nossos clientes, oferecendo pratos preparados com ingredientes frescos e de qualidade, em um ambiente acolhedor e sofisticado. Venha nos visitar e descubra o sabor que tornou o Varandas um ícone em Bonito.</Parag>
            </Wrapper1>
        </SchaduleContent>
        </HistoryWrapper>
      
    </>
  )
}


export default HistorySection

