import styled from 'styled-components';

export const ContentBody = styled.div`
    display: flex;
    width: 100%;
    justify-content: center;
    background: #964F39;
    margin-top: -20px;
    overflow: hidden;

`


export const WrapperName = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 100%;
    height: 100%;
    margin: 50px 0;
`

export const TitlePage = styled.h1`
    font-size: 40px;
    color: #FDDFC7;
    margin-bottom: 30px;
`

export const WrapCards = styled.div`
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 10px;
    flex-wrap: wrap;


    @media screen and (max-width: 350px) {
        flex-direction: column;
    }

    @media screen and (max-width: 950px) {
        grid-template-columns: repeat(2, 1fr);
    }

    @media screen and (max-width: 600px) {
        grid-template-columns: 1fr;
    }
`


export const WrapperSection = styled.div`
    width: 90%;
    align-self: center;
    margin-bottom: 60px;
`
export const CardProd = styled.div`
    display: flex;
    background: rgba(255, 195, 152, 0.8);
    justify-content: center;
    transition: all 0.5s ease-in-out;
    transform: scale(1);
    border-radius: 10px;


    @media screen and (max-width: 350px) {
        width: 100%;
    }

    &:hover {
        transform: scale(0.99);
        transition: all 0.5s ease-in-out;
    }
`
    
    
export const WrapperInfos = styled.div`
    display: flex;
    width: 100%;
    padding: 10px;
    flex-direction: column;
    justify-content: space-between;
`
    
export const WrapFoto = styled.div`
    display: flex;
    width: 100%;
    height: 25%;
    justify-content: center;
    margin-bottom: 1%;
    
    `
    
    export const FotoItem = styled.img`
    display: flex;
    object-fit: cover;
    width: 100%;
    height: auto;
    border-radius: 10px;
`
    
export const WrapperNomeDesc = styled.div`
    display: flex;
    flex-direction: column;
    overflow-y: hidden;

`
    
    export const WrapperDescEPrice = styled.div`
    display: flex;
    width: 100%;
    height: 74%;
    flex-direction: column;
    justify-content: space-between;

`
    
export const H2Nome = styled.h2`
    display: flex;
    color: #964F39;
    font-size: 1.6rem;
    width: 100%;
    margin-bottom: 15px;
    overflow-y: hidden;
    padding: 0 0 40px 0;
`
    
    export const DescProdAlpha = styled.p`
    display: flex;
    width: 100%;
    height: 50%;
    align-items: center;
    color: white;
    margin-bottom: 15px;
    font-size: 1rem;
    hyphens: auto;
    border-radius: 15px;
    padding: 20px;
    background: rgba(255, 195, 152, 0.4);
    overflow-y: hidden;
    
    `
    
    export const WrapperDesc = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 40%;
    margin-bottom: 10px;
    padding: 5px;
    overflow-y: hidden;
`
    export const SpanLang = styled.img`
    display: flex;
    width: 10%;
    `
    
    export const DescProd = styled.p`
    display: flex;
    margin-left: 15px;
    color: white;
    width: 90%;
    font-size: 0.9rem;
    padding: 5px;
`

export const Icon = styled.img`
    display: flex;
    width: 86px;
    height: 43px;
    margin: 15px;
`

export const WrapperPrice = styled.div`
    display: flex;
    widht: 90%;
    height: auto;
    justify-content: flex-end;
    align-items: flex-end;
    bottom: 10px;
    right: 10px;
`
export const PriceH2 = styled.h2`
    display: flex;
    font-size: 1.8em;
    padding: 5px;
    border-radius: 10px;
    background: rgba(255, 195, 152, 0.4);
    color: #964F39;
    // margin-bottom: -10px;

`





// Adicionais

export const CardProdNoImg = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background: rgba(255, 195, 152, 0.8);
    align-items: center;
    border-radius: 10px;
    transition: all 0.2s ease-in-out;


    @media screen and (max-width: 350px) {
        width: 100%;
    }

    &:hover {
        transform: scale(0.99);
    }

`

export const NameNoImg = styled.h2`
    display: flex;
    color: #964F39;
    overflow-Y: hidden;
    margin-bottom: 20px;
    font-size: 1.8rem;
`
export const DescIng = styled.p`
    font-size: 1.2rem;
    color: white;
    font-style: italic;
`
export const DescEsp = styled.p`
    font-size: 1.2rem;
    color: white;
    font-style: italic;

`

export const WrapperPriceNoImg = styled.div`
    display: flex;
    justify-content: flex-end;
    width: 100%;
`

export const PriceNoImg = styled.p`
    display: flex;
    // justify-content: flex-end;
    margin: 0 20px 20px 0;
    padding: 10px;
    border-radius: 10px;
    font-size: 1.4rem;
    background: rgba(255, 195, 152, 0.4);
    color: #964F39;


`



