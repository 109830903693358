import React from 'react'
import IntroCardapioSection from '../components/cardapio/IntroCardapio';
import NavMenuCardapio from '../components/cardapio/NavMenuCardapio';
import ContentBody from '../components/cardapio/ContentBodyCardapio';
import ButtonScrollToTop from '../components/cardapio/ButtonScrollToTop/index';
import { animateScroll as scroll } from 'react-scroll';



const CardapioPage = () => {

  const scrollToTop = () => {
    scroll.scrollToTop();
  }
  scrollToTop();

  return (
    <>
      <ButtonScrollToTop />
      <IntroCardapioSection />
      <NavMenuCardapio />
      <ContentBody />

    </>
  )
}



export default CardapioPage;
