import styled from 'styled-components';



export const Button = styled.div`
    background: #FF9746;
    white-space: nowrap;
    padding: 14px 48px;
    color: #060606;
    display: flex;
    height: 80px;
    min-width: 320px;
    outline: none;
    cursor: pointer;    
    justify-content: center;
    align-items: center;
    transition: all 0.2s ease-in-out;
    font-size: 32px;
    text-transform: uppercase;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;


    &:hover {
    transition: all 0.2s ease-in-out;
    background: #FCC9A1;
    }


    @media screen and (max-width: 768px) {
        font-size: 20px;
        min-width: 250px;
        height: 65px;

    }
`
