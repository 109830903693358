import styled from 'styled-components';
import { FaArrowUp } from 'react-icons/fa';


export const Button = styled.button`
    position: fixed;
    bottom: 20px;
    right: 20px;
    width: 80px;
    height: 80px;
    border-radius: 20%;
    background: #E38339;
    cursor: pointer;
    border: none;
    z-index: 1000;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: 0.8s all ease-in-out;

    &:hover {
        scale: 1.1;
    }

`



export const ArrowTop = styled(FaArrowUp)`
    font-size: 35px;
    color: #FFF;
    transition: all 0.2s ease;
    
    &:hover {
        color: #2E0011;
        transition: all 0.2s ease;
    }
`