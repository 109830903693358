import React from 'react'
import { FaArrowLeft, FaFacebook, FaInstagram } from 'react-icons/fa';
import LogoVarandas from '../../../images/logo.png';


import { 
    IntroCardapioWrapper,
    WrapperMenu,
    BackHome,
    WrapperImg,
    TitleCard,
    WrapperInfosCard,
    Varandas,
    TextCard,
    Text01,
    Text02,
    NavRedes,
    NavRedesLink,
 } from './introCardapioElements';

const IntroCardapioSection = () => {

  return (
    <>
    <IntroCardapioWrapper>
        <WrapperMenu>
            <BackHome to="/"><FaArrowLeft /></BackHome>

            <WrapperImg>
                <TitleCard>Cardápio</TitleCard>
                <WrapperInfosCard>
                    <Varandas src={LogoVarandas} />
                    <TextCard>
                        <Text01>HORÁRIO DE FUNCIONAMENTO:</Text01>
                        <Text02>DE QUINTA À TERÇA<br/>11H ÀS 23H</Text02>
                    </TextCard>
                </WrapperInfosCard>
            </WrapperImg>

            <NavRedes>
                <NavRedesLink target="blank" to="https://www.facebook.com/varandasbonitoms/"><FaFacebook /></NavRedesLink>
                <NavRedesLink target="blank" to="https://www.instagram.com/varandasbonitoms"><FaInstagram /></NavRedesLink>
            </NavRedes>
        </WrapperMenu>

        
    </IntroCardapioWrapper>
    </>
  )
}

export default IntroCardapioSection;
