import React from 'react'
import { FaBars, FaFacebook, FaInstagram } from 'react-icons/fa';
import { scrollToTop, scrollToSection } from '../../utils/scrollUtils';
import { 
    Nav, 
    NavbarContainer, 
    NavLogo, 
    HeroLogo, 
    MobileIcon, 
    NavMenu, 
    NavItem, 
    NavLinks,
    NavRedes,
    NavRedesLink
} from './NavbarElements';
import image from '../../images/logo.png';



const Navbar = ({ toggle }) => {
  return (
    <>
      <Nav className='navbar'>
        <NavbarContainer>
            <NavLogo 
                onClick={scrollToTop}
            >
                <HeroLogo src={image}/>
            </NavLogo>
            <MobileIcon onClick={toggle}>
                <FaBars />
            </MobileIcon>
            <NavMenu>
                <NavItem>
                    <NavLinks onClick={() => scrollToSection('varandas', 80)}>Varandas</NavLinks>
                </NavItem>
                <NavItem>
                    <NavLinks onClick={() => scrollToSection('ambiente', 60)}>Ambiente</NavLinks>
                </NavItem>
                <NavItem>
                    <NavLinks onClick={() => scrollToSection('culinaria', 80)}>Culinária</NavLinks>
                </NavItem>
                <NavItem>
                    <NavLinks onClick={() => scrollToSection('fotos', 80)}>Fotos</NavLinks>
                </NavItem>
                <NavItem>
                    <NavLinks onClick={() => scrollToSection('cardapio', 80)}>Cardápio</NavLinks>
                </NavItem>
            </NavMenu>

            <NavRedes>
            <NavRedesLink target="blank" to="https://www.facebook.com/varandasbonitoms/"><FaFacebook /></NavRedesLink>
                <NavRedesLink target="blank" to="https://www.instagram.com/varandasbonitoms"><FaInstagram /></NavRedesLink>
            </NavRedes>
        </NavbarContainer>
      </Nav>
    </>
  )
}

export default Navbar;
