import img01 from '../../images/safadinha_alpha.jpg';
import img02 from '../../images/02.jpg';
import img03 from '../../images/03.jpg';

export const items = [
    {
        image: img01,
        title: "Piraputanga Safadinha"
    },
    {
        image: img02,
        title: "Moqueca de Pintado"
    },
    {
        image: img03,
        title: ""
    }
    
    
];