import styled from 'styled-components';
import { Link as LinkR } from 'react-router-dom';



export const FooterDiv = styled.div`
    background: #FF9746;
    padding: 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media screen and (max-width: 768px) {
        flex-direction: column;
    }
`
export const InfosFooter = styled.div`
    display: flex;
    flex-direction: column;
    max-width: 400px;

    @media screen and (max-width: 768px) {
        align-items: center;
    }
`
export const InfoRedes = styled.div``
export const FooterLinks = styled(LinkR)`
    color: white;
    font-size: 2.4rem;
    margin-right: 10px;
    transition: all 0.2s ease-in-out;


    &:hover {
        color: #2E0011;
    } ;
`
export const InfoP = styled.p`
    color: white;


    @media screen and (max-width: 768px) {
        text-align: center;
    }
`
export const MapFooter = styled(LinkR)`
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    text-decoration: none;
    @media screen and (max-width: 768px) {
        margin-top: 20px;
        align-items: center;
}
`
export const MapImg = styled.img`
border: solid 5px #FF9746;
cursor: pointer;
width: 50%;
    transition: all 0.2s ease-in-out;


&:hover {
    border: solid 5px #2E0011;
}


@media screen and (max-width: 768px) {
    width: 100%;
}

`
