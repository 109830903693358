import { useState, React, useRef, useLayoutEffect, useEffect } from 'react';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

import {
  Carousel,
    WrapperCard,
    CarouselCard,
    ImgSlider,
    Overlay,
    ArrowRight,
    Button
} from './SliderElements';


const Slider = ({images}) => {
  const [current, setCurrent] = useState(0);



  const ref = useRef(null);

  useLayoutEffect(() => {
    gsap.registerPlugin(ScrollTrigger);
    gsap.to(".carousel_wrapper", {
        opacity: 1,
        scrollTrigger:{
            trigger: ".card_img",
            start: "top 70%",
            end: "bottom 99%",
            scrub: true,

        }
    })

    ScrollTrigger.refresh();
    return() => {
        gsap.killTweensOf(".carousel_wrapper");
    }
}, [])



  const slideRight = () => {
    setCurrent(current === images.length -1 ? 0 : current + 1);
  };

  const slideLeft = () => {
    setCurrent(current === 0 ? images.length - 1 : current - 1);
  }



  return (
    <Carousel id='fotos' className='carousel'>

      <div className='schadule_card'
            style={{
                display: "flex",
                width: "100%",
                flexDirection: "column",
                alignItems: "center",
                overflow: "hidden",
            }}
        >
        <WrapperCard ref={ref} className='carousel_wrapper'>
            {images.map((image, index) => {
              return(
              <CarouselCard key={index} className={index === current 
              ? "carousel_card_active" 
              : "carousel_card"
              }
              >
                <ImgSlider className='card_img' src={image.image} />
                <Overlay className='card_overlay'>
                  <h2 className='card_title'>
                    {image.title}
                  </h2>

                </Overlay>
                <Button className='arrowRight' onClick={slideRight}>{<ArrowRight />}</Button>
                <Button className='arrowLeft' onClick={slideLeft}>{<ArrowRight />}</Button>
              </CarouselCard>
              )
              

            })}

        </WrapperCard> 
        </div>
    </Carousel>

  )
}

export default Slider
