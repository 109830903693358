import React, { useRef, useLayoutEffect } from 'react';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

import img from '../../images/culinaria_peixes.png';
import img2 from '../../images/culinaria_carnes.png';
import img3 from '../../images/culinaria_petiscos.png';

import { 
    WrapperCulinaria,
    TitleCulinaria,
    WrapperItems,
    ItemCulinaria,
    Img,
    TitleItem,
    TextItem,
    TitleSeparador
 } from './CulinariaElements';

const CulinariaSection = () => {
    const ref = useRef(null);

    useLayoutEffect(() => {
        gsap.registerPlugin(ScrollTrigger);
        gsap.to(".title", {
            opacity: 1,
            scrollTrigger:{
                trigger: "#culinaria",
                start: "top center",
                end: "top 150px",
                scrub: true,
            }
        })
        return() => {
            gsap.killTweensOf(".title");
        }
    }, [])

    useLayoutEffect(() => {
        gsap.registerPlugin(ScrollTrigger);
        gsap.to(".cardOne", {
            x: 0,
            opacity: 1,
            scale: 1,
            scrollTrigger:{
                trigger: "#culinaria",
                start: "top 200px",
                end: "top 150px",
                scrub: true,
            }
        })
        return() => {
            gsap.killTweensOf(".cardOne");
        }
    }, [])

    useLayoutEffect(() => {
        gsap.registerPlugin(ScrollTrigger);
        gsap.to(".cardTwo", {
            x: 0,
            opacity: 1,
            scale: 1,
            scrollTrigger:{
                trigger: "#culinaria",
                start: "top 200px",
                end: "top 150px",
                // markers: true,
                scrub: true,
            }
        })
        return() => {
            gsap.killTweensOf(".cardTwo");
        }
    }, [])

    useLayoutEffect(() => {
        gsap.registerPlugin(ScrollTrigger);
        gsap.to(".cardThree", {
            x: 0,
            opacity: 1,
            scale: 1,
            scrollTrigger:{
                trigger: "#culinaria",
                start: "top 200px",
                end: "top 150px",
                // markers: true,
                scrub: true,
            }
        })
        return() => {
            gsap.killTweensOf(".cardThree");
        }
    }, [])


  return (
    <>

        <WrapperCulinaria ref={ref} id='culinaria'>

        <div 
            style={{
                display: "flex",
                width: "100%",
                border: 0,
                flexDirection: "column",
                alignItems: "center",
            }}
        >
            <TitleCulinaria className='title'>Culinária</TitleCulinaria>
            <TitleSeparador className='title'>.</TitleSeparador>

            <WrapperItems>
                <ItemCulinaria 
                    className='cardOne'
                    style={{
                        opacity: 0,
                    }}
                    >
                    <Img src={img}/>
                    <TitleItem>Peixes</TitleItem>
                    <TextItem>Descubra a culinária regional com deliciosos pratos à base de peixe, preparados com ingredientes frescos e sabores autênticos da região, que encantam o paladar. </TextItem>
                </ItemCulinaria>

                <ItemCulinaria
                    className='cardTwo'
                    style={{
                        opacity: 0,
                    }}
                >
                    <Img src={img2}/>
                    <TitleItem>Carnes Vermelhas</TitleItem>
                    <TextItem>Aprecie a culinária regional com pratos de carne vermelha, preparados com cortes selecionados e temperos especiais, oferecendo uma explosão de sabores tradicionais.</TextItem>
                </ItemCulinaria>

                <ItemCulinaria
                    className='cardThree'
                    style={{
                        opacity: 0,
                    }}
                >
                    <Img src={img3}/>
                    <TitleItem>Petiscos</TitleItem>
                    <TextItem>Delicie-se com os petiscos regionais, feitos com ingredientes frescos e toques exclusivos, perfeitos para acompanhar uma boa conversa e uma experiência saborosa.</TextItem>
                </ItemCulinaria>
            </WrapperItems>
            </div>

        </WrapperCulinaria>
      
    </>
  )
}

export default CulinariaSection
