import styled from 'styled-components';

export const WrapperContainer = styled.div`
    position: relative;
    flex: 1;
    // height: 90vh;
    overflow: visible;
    border-top: solid 27px #2E0011;
    background: #FF9746;

    @media screen and (max-width: 768px) {
        border-top: solid 12px #2E0011;
        // height: 70vh;

    }
`

export const PrimaveraOne = styled.img`
    position: absolute;
    z-index: 90;
    top: -15%; 
    right: -2px;
    width: 28%; 
    transform: scale(2) translateX(-80px);
    
    @media screen and (max-width: 768px) {
        width: 40%;
        transform: scale(2) translateX(-50px);
        right: -40px;

}
`
export const Container = styled.div`
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;
    padding: 60px;
    overflow-y: hidden;
    
    @media screen and (max-width: 768px) {
        justify-content: center;
        flex-direction: column;
        padding: 20px;
        }
`
export const WrapperText = styled.div`
    display: flex;
    width: 100%;
    height: 100%;
    overflow-y: hidden;
    margin-bottom: 30px;
    align-items: center;
    justify-content: center;

    @media screen and (max-width: 768px) {
        text-align: center;
    }
    `
    
export const Text = styled.h1`
    display: flex;
    font-size: 4.4rem;
    color: #2E0011;
    font-family: "Niconne";
    padding: 10px;
    
    @media screen and (max-width: 424px) {
        font-size: 2.3rem;
    }
        @media screen and (max-width: 768px) {
        font-size: 3rem;
    }
    `
        
export const WrapperImgAmbiente = styled.div`
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
        margin-left: 50px;
        
        @media screen and (max-width: 768px){
            margin-left: 0;
        }
        `
        
export const ImgAmbiente = styled.img`
    display: flex;
    width: 70%;
    height: auto;

    @media screen and (max-width: 768px) {
        width: 90%;
    }
`

export const PrimaveraTwo = styled.img`
    position: absolute;
    width: 28%;
    left: -5px;
    bottom: -18%;
    z-index: 99;
    transform: scale(2) translateX(80px);


    @media screen and (max-width: 768px) {
        // bottom: -20%;
        width: 40%;
        transform: scale(2) translateX(50px);
        left: -30px;
    }
`