// utils/scrollUtils.js
import { gsap } from 'gsap';
import { ScrollToPlugin } from 'gsap/ScrollToPlugin';

// Ativando o plugin ScrollTo do GSAP
gsap.registerPlugin(ScrollToPlugin);

/**
 * Rola suavemente para o topo da página.
 */
export const scrollToTop = () => {
  gsap.to(window, { duration: 1, scrollTo: { y: 0 } });
};

/**
 * Rola suavemente para uma seção específica da página.
 * @param {string} sectionId - O ID da seção para rolar.
 * @param {number} offsetY - Offset vertical opcional.
 */
export const scrollToSection = (sectionId, offsetY = 0) => {
  gsap.to(window, { duration: 1, scrollTo: { y: `#${sectionId}`, offsetY } });
};
