// Importações devem ser feitas no topo do arquivo
import React, { useState, useRef, useLayoutEffect } from 'react';
import Video from '../../videos/hero_alpha_fachada.mp4';
import Background from '../../images/hero_background.jpg';
import { gsap } from 'gsap';
import { scrollToSection } from '../../utils/scrollUtils';


// Importação de componentes e elementos do projeto
import { 
    HeroContainer,
    HeroBg,
    VideoBg,
    HeroImg,
    HeroContent,
    HeroH1,
    HeroBtnWrapper,
    ArrowRight,
    ArrowForward,
} from './HeroElements';

import { Button } from '../ButtonElements';

const HeroSection = () => {
    const [hover, setHover] = useState(false);
    const onHover = () => setHover(!hover);

    useLayoutEffect(() => {
        gsap.to(".content", {
            y: 0,
            opacity: 1,
        })
       
        return() => {
            gsap.killTweensOf(".content");
        }
    }, [])
    

   

    return (
        <HeroContainer className='topPage'>      
            <HeroBg>
                <VideoBg  autoPlay loop muted playsInline src={Video} type="video/mp4" />
                {/* <HeroImg src={Background} /> */}
            </HeroBg>        
            <HeroContent className='content'>
                <div
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        flexDirection: "column",
                        alignItems: "center",
                    }}
                >
                    <HeroH1>A Varanda do Coração de Bonito</HeroH1>
                    <HeroBtnWrapper>
                        <Button onClick={() => { scrollToSection('varandas', 80) }}>
                            CONHECER {hover ? <ArrowForward /> : <ArrowRight />}
                            </Button>
                    </HeroBtnWrapper>
                </div>
            </HeroContent>
        </HeroContainer>
    );
};

export default HeroSection;
