import styled from 'styled-components';

export const FullWrapper = styled.div`
    display: flex;
    width: 100%;
    overflow-y: hidden;


    @media screen and (max-width: 1150px) {
        flex-direction: column;
    }
`

export const WrapperTitle = styled.div`
    display: flex;
    position: absolute;
    flex-direction: column;
    align-items: center;
    width: 100%;
    overflow-y: hidden;
    z-index: 2000;
`

export const TitleGalery = styled.h2`
    font-size: 40px;
    margin: 50px 0 20px 0;
    text-transform: uppercase;
    display: flex;
    justify-content: center;
    color: white;
    width: 100%;
    text-align: center;
`

export const TitleSeparador = styled.div`
    width: 30%;
    height: 15px;
    background: white;
    margin-bottom: 60px;
    justify-content: center;
    color: white;
    overflow-y: hidden;
`

export const WrapperAllPhotos = styled.div`
    display: flex;
    width: 100%;
    background: black;
`

export const FirstItem = styled.div`
    margin: 0;
    
    display: flex;
    object-fit: cover;

`
export const Img01 = styled.img`
    width: 100%;
    height: auto;
    object-fit: cover;
    transition: 0.8s all ease;

    &:hover {
        scale: .975;
    }


`

export const WrapperAlpha = styled.div`
    display: flex;
    flex-direction: column;
`
export const SecondItem = styled.div`
    display: flex;

`
export const Img02 = styled.img`
    width: 100%;
    object-fit: cover;

    &:hover {
        transition: all 0.3s ease-in-out;
        scale: .975;
    }

`
export const Img03 = styled.img`
    width: 100%;
    object-fit: cover;

    &:hover {
        transition: all 0.3s ease-in-out;
        scale: .975;
    }


`
export const FinalItem = styled.div`
    display: flex;
`
export const Img04 = styled.img`
    width: 100%;
    object-fit: cover;

    &:hover {
        transition: all 0.3s ease-in-out;
        scale: .975;
    }


`
export const Img05 = styled.img`
    width: 40%;
    object-fit: cover;

    &:hover {
        transition: all 0.3s ease-in-out;
        scale: .975;
    }
`

