import React from 'react';
import { scrollToSection, scrollToTop } from '../../utils/scrollUtils';


import { 
    SidebarContainer, 
    Icon, 
    CloseIcon,
    SidebarWrapper,
    SidebarMenu,
    SidebarLink,
    SideRedesWrap,
    SidebarRoute

} from './SidebarElements';
import { FaFacebook, FaInstagram } from 'react-icons/fa';




function Sidebar({ isOpen, toggle }) {

    const handleClick = (section, offsetY) => {
        scrollToSection(section, offsetY); // Rola para a seção desejada
        toggle(); // Fecha o Sidebar
    };
    

    return (
        <SidebarContainer isOpen={isOpen} onClick={toggle}>
        <Icon onClick={scrollToTop}>
            <CloseIcon />
        </Icon>
        <SidebarWrapper>

            <SidebarMenu>
                <SidebarLink onClick={() => {handleClick('varandas', 80)}}>
                    Varandas
                </SidebarLink>
                <SidebarLink onClick={() => {handleClick('ambiente', 80)}}>
                    Ambiente
                </SidebarLink> 
                <SidebarLink onClick={() => {handleClick('culinaria', 80)}}>
                    Culinária
                </SidebarLink>
                <SidebarLink onClick={() => {handleClick('fotos', 80)}}>
                    Fotos
                </SidebarLink>
                <SidebarLink onClick={() => {handleClick('cardapio', 80)}}>
                    Cardápio
                </SidebarLink>
            </SidebarMenu>

            <SideRedesWrap>
                <SidebarRoute target="blank" to="https://www.facebook.com/varandasbonitoms/"><FaFacebook /></SidebarRoute>
                <SidebarRoute target="blank" to="https://www.instagram.com/varandasbonitoms"><FaInstagram /></SidebarRoute>
            </SideRedesWrap>

            
        </SidebarWrapper>

      
    </SidebarContainer>
  )
}

export default Sidebar;
