import React, {useEffect} from 'react'
import './App.css';
import Home from './pages';
import Cardapio from './pages/cardapio'
import { BrowserRouter, Routes, Route } from 'react-router-dom';


function App() {
  useEffect(() => {
    document.documentElement.lang = 'pt-BR'; // Define a língua da página para português do Brasil
  }, []);
  return (
    <BrowserRouter>
      <Routes>
        <Route path='/' element={<Home />}></ Route>
        <Route path='/cardapio' element={<Cardapio />}></ Route>
        <Route path="*" element={<h1>Página não encontrada</h1>} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
