import styled from 'styled-components';
import {MdArrowForwardIos, MdArrowForward} from 'react-icons/md';



export const HeroContainer = styled.div`
    display: flex;
    justify-content: center;
    width: 100%;
    align-items: center;
    background: #FF9746;
    height: 100vh;
    position: relative;
    z-index: 1;
/* add before styles */


`


export const HeroBg = styled.div`
    position: relative;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
`



export const VideoBg = styled.video`
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover;
    background: #FCC9A1;
    position: absolute;
`

export const HeroImg = styled.img`
    // display: flex;
    width: 100%;
    height: 100%;
    object-fit: cover;
    -o-object-fit: cover;
    background: #FFEBCC;

`


export const HeroContent = styled.div`
    display: flex;
    z-index: 3;
    max-width: 1200px;
    position: absolute;
    padding: 8px 24px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow-y: hidden;
`


export const HeroH1 = styled.h1`
    font-size: 110px;
    magin-bottom: 300px;
    color: #FF9746;
    font-family: Arial Black;
    text-align: center;
    font-family: Niconne;
    max-width: 780px;
    overflow: hidden;


    @media screen and (max-width: 768px) {
        font-size: 60px;
    }

`


export const HeroBtnWrapper = styled.div`
    display: flex;
    margin-top: 32px;
    align-items: center;
    background: red;
    color: red;
    z-index: 100;

    &:hover {
    background: #fff;
    }

`


export const ArrowForward = styled(MdArrowForward)`
    margin-left: 15px;
    font-size: 35px;
`
export const ArrowRight = styled(MdArrowForwardIos)`
    margin-left: 15px;
    font-size: 35px;
`



