import React from 'react'
import { FaFacebook, FaInstagram } from 'react-icons/fa';

import Map from '../../images/map.png';


import { 
    FooterDiv,
    InfosFooter,
    InfoRedes,
    FooterLinks,
    InfoP,
    MapFooter,
    MapImg
 } from './FotterElements';

const Footer = () => {
  return (
    <>
        <FooterDiv>
            <InfosFooter>
                <InfoRedes>
                    <FooterLinks target="blank" to="https://www.facebook.com/varandasbonitoms/"><FaFacebook /></FooterLinks>
                    <FooterLinks target="blank" to="https://www.instagram.com/varandasbonitoms"><FaInstagram /></FooterLinks>

                </InfoRedes>

                <InfoP>
                    Contato: 67 9 9142-4788<br/>
                    Endereço: Rua Pilad Rebua, 1883<br/>
                    Centro, Bonito - MS, 79290-000<br/>
                    100 metros da Praça da Liberdade<br/>
                </InfoP>
            </InfosFooter>

            <MapFooter target= "blank" to="https://maps.app.goo.gl/7iFJ4YdYSV3yNab7A">
            <p style={{
                fontSize : '14px',
                color: '#fff',
                textAlign: 'right',
                border: 'solid 5px #FF9746',
                }
                
            }
                
                >
                    Clique aqui e Veja no Mapa</p>
                <MapImg src={Map} />
            </MapFooter>
        </FooterDiv>
      
    </>
  )
}

export default Footer;
