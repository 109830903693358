import styled from 'styled-components';

export const HistoryWrapper = styled.div`
    display: flex;
    flex: 1;
    padding: 60px;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100%;
    background: #964F39;
    z-index: 5;
    overflow: hidden;

    `
    export const SchaduleContent = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    opacity: 0;
    transform: translateY(100px);    
`
export const TitleHistory = styled.h2`
    display: flex;
    font-size: 32px;
    margin-bottom: 20px;
    text-transform: uppercase;
    text-align: center;
    color: white;
    align-self: center;
    justify-content: center;
`

export const TitleSeparador = styled.div`
    display: flex;
    width: 37%;
    height: 9px;
    background: white;
    margin-bottom: 40px;
    overflow-y: hidden;

`

export const Wrapper1 = styled.div`
    display: flex;
    align-items: center;
    flex-direction: column;
    backgorund: yellow;
    padding: 20px;
    margin-bottom: 40px;


    @media screen and (max-width: 768px) {
        flex-direction: column;
        margin-bottom: 20px;

    }
`

export const HistoryImg1 = styled.img`
    display: flex;
    width: 50%;
    margin: 0 20px 20px 20px;

    @media screen and (max-width: 768px) {
        width: 250px;
    }

`

export const Parag = styled.p`
    color: white;
    font-size: 24px;
    max-width: 600px;
    text-align: center;

    @media screen and (max-width: 768px) {
        font-size: 20px;
        max-width: 300px;

    }
`


export const Wrapper2 = styled.div`
    display: flex;
    justify-content: center;
    backgorund: yellow;
    padding: 20px;
    align-items: center;
    margin-bottom: 100px;

    @media screen and (max-width: 1200px) {
        flex-direction: column;
        margin-bottom: 60px;

    }

`

export const Parag2 = styled.p`
    color: white;
    font-size: 32px;
    max-width: 800px;
    text-align: right;

    @media screen and (max-width: 768px) {
        font-size: 20px;
        max-width: 400px;
        text-align: center;
    }
`