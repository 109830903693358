import React, { useEffect, useState, useRef } from 'react';
import throttle from 'lodash.throttle';
import { scrollToSection } from '../../../utils/scrollUtils';

import {
  WrapperNavMenu,
  SchaduleElementsMenu,
  NavMenu,
  NavItem,
  NavLinks,
  SchaduleScrollButtomOne,
  SchaduleScrollButtomTwo,
  ScrollButton,
  ButtonsScroll
  
} from './navMenuCardapioElements';

function NavMenuCardapio() {
  const menuRef = useRef(null);
  const buttonsRef = useRef(null);
  const [activeMenuFixed, setActiveMenuFixed] = useState(false);

  // Scroll Control Functions
  const scrollLeft = () => {
    buttonsRef.current.scrollBy({ left: -150, behavior: 'smooth' }); // Scroll para a esquerda
  };

  const scrollRight = () => {
    buttonsRef.current.scrollBy({ left: 150, behavior: 'smooth' }); // Scroll para a direita
  };

  // Scroll Throttle
  useEffect(() => {
    const throttledScroll = throttle(() => {
      const shouldBeFixed = window.scrollY > 250;
      setActiveMenuFixed(shouldBeFixed);
    }, 200);

    window.addEventListener('scroll', throttledScroll);
    return () => {
      window.removeEventListener('scroll', throttledScroll);
    };
  }, []);

  return (
    <WrapperNavMenu ref={menuRef} activeMenuFixed={activeMenuFixed}>
      <SchaduleElementsMenu>
        
      <NavMenu activeMenuFixed={activeMenuFixed}  ref={buttonsRef}>
        {[
          'Couvert',
          'Saladas',
          'Piraputanga',
          'Pintado',
          'Tilápia',
          'Pacu',
          'Carne Bovina',
          'Adicionais',
          'Para uma Pessoa',
          'Porções',
          'Bebidas',
          'Sucos',
          'Coquetéis',
          'Drinks sem álcool',
          'Batidas',
          'Doses',
          'Cafés',
          'Sobremesas',
        ].map((item, index) => (
          <NavItem key={index}>
            <NavLinks
              onClick={() => {
                const id = item
                  .toLowerCase()
                  .normalize('NFD')
                  .replace(/[\u0300-\u036f]/g, '')
                  .replace(/\s+/g, '_');
                scrollToSection(id, 110);
              }}
            >
              {item}
            </NavLinks>
          </NavItem>
        ))}
      </NavMenu>

      <ButtonsScroll>
        <SchaduleScrollButtomOne>
          <ScrollButton onClick={scrollLeft}>{'<'}</ScrollButton> {/* Botão para esquerda */}
        </SchaduleScrollButtomOne>
        <SchaduleScrollButtomTwo>
          <ScrollButton onClick={scrollRight}>{'>'}</ScrollButton> {/* Botão para direita */}
        </SchaduleScrollButtomTwo>
      </ButtonsScroll>

      </SchaduleElementsMenu>
      
    </WrapperNavMenu>
  );
}

export default NavMenuCardapio;
