import styled from 'styled-components';
import { Link as LinkR } from 'react-router-dom';

export const Nav = styled.nav`
    background: #FFF;
    border-bottom: solid 7px #FF9746;
    height: 80px;
    // margin-top: -80px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1rem;
    position: fixed;
    top: 0;
    z-index: 100;
    overflow: hidden;
    width: 100%;


    @media screen and (max-width: 960px) {
        transition: 0.8s all ease;
    }
`

export const NavbarContainer = styled.div`
    display: flex;
    justify-content: space-between;
    height: 80px;
    z-index: 1;
    width: 100%;
    padding: 0 30px;
    // max-width: 1100px;
    // position: fixed;
`

export const NavLogo = styled(LinkR)`
    cursor: pointer;
    display: flex;
    align-items: center;
    // margin-left: 24px;


`

export const HeroLogo = styled.img`
    width: 100px;
`

export const MobileIcon = styled.div`
    display: none;

    @media screen and (max-width: 768px) {
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        transform: translate(-100%, 60%);
        font-size: 1.8rem;
        cursor: pointer;
        color: #000;
    }
`

export const NavMenu = styled.ul`
    display: flex;
    align-items: center;
    list-style: none;
    text-align: center;
    // margin-right: -22px;
    height: 100%;
    

    @media screen and (max-width: 768px) {
        display: none;
    }
`

export const NavItem = styled.li`
    height: 40px;
    
    &.active {
        border-bottom: 3px solid #FF9746;
    }
`

export const NavLinks = styled.a`
    color: #2E0011;
    display: flex;
    align-items: center;
    text-decoration: none;
    padding: 0 1rem;
    height: 100%;
    cursor: pointer;
    padding-top: 3px;
    border-bottom: 3px solid #FFF;
    
    &:hover {
        border-bottom: 3px solid #FF9746;
        color: #FF9746;
    }

    &[href]:target {
    background: blue;
    font-weight: bold;
  }

`


export const NavRedes = styled.nav`
    display: flex;
    align-items: center;


`

export const NavRedesLink = styled(LinkR)`
    border-radius: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px 22px;
    color: #FF9746;
    font-size: 2rem;
    outline: none;
    border: none;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    text-decoration: none;
    
    @media screen and (max-width: 768px) {
        display: none;
    }
    
    &:hover {
        transition: all 0.2s ease-in-out;
        // background: #fff;
        color: #2E0011;
        }

`
