import styled from "styled-components";
import { Link as LinkS } from 'react-scroll';

export const WrapperNavMenu = styled.div.withConfig({
    shouldForwardProp: (prop) => prop !== "activeMenuFixed",
    })`
    overflowX: 'auto';
    cursor: ${(props) => (props.isDragging ? 'grabbing' : 'grab')};
    whit-space: 'nowrap';
    position: ${({ activeMenuFixed }) => (activeMenuFixed ? 'fixed' : 'sticky')};
    top: ${({ activeMenuFixed }) => (activeMenuFixed ? '0' : 'auto')};
    right: ${({ activeMenuFixed }) => (activeMenuFixed ? '0' : 'auto')};
    left: ${({ activeMenuFixed }) => (activeMenuFixed ? '0' : 'auto')};
    z-index: 20001;
    flex: 1;
`


export const SchaduleElementsMenu = styled.div`
    display: flex;
    justify-content: space-around;
    align-items: center;
`



export const NavMenu = styled.ul.withConfig({
    shouldForwardProp: (prop) => prop !== "activeMenuFixed",
    })`
    display: flex;
    transition: all 0.3s ease-in-out;
    overflow-x: auto;
    margin-left: 30px;
    scroll-behavior: smooth;
    white-space: nowrap;

    background: ${({ activeMenuFixed }) => (activeMenuFixed ? '#FFF' : 'transparent')};
    padding: ${({ activeMenuFixed }) => (activeMenuFixed ? '15px 110px 15px 15px' : '0 110px 0 0')};
    white-space: nowrap;
    pointer-events: ${({ isDragging }) => (isDragging ? 'none' : 'auto')};

    &::-webkit-scrollbar { 
        display: none;
    }
  scroll-behavior: smooth; /* Adiciona suavidade ao scroll */
`

export const NavItem = styled.li`
    display: flex;
    margin-right: 30px;
    min-width: 180px;
    text-align: center;
    justify-content: center;
    cursor: pointer;

    &.active {
        transition: all 0.2s ease-in-out;
        background: #2E0011;
        border: solid 5px #FDDFC7;
    }

`
export const NavLinks = styled.div`
    background: #E38339;
    min-width: 200px;
    padding: 10px 10px;
    cursor: pointer;
    color: #FDDFC7;
    text-transform: uppercase;
    border: solid 5px #E38339;
    transition: all 0.5s ease-in-out;
    
    &:hover {
        transition: all 0.2s ease-in-out;
        background: #2E0011;
        border: solid 5px #FDDFC7;
    }

    &.active {
        transition: all 0.2s ease-in-out;
        background: #2E0011;
        border: solid 5px #FDDFC7;
    }    

`



export const ButtonsScroll = styled.div`
    display: flex;
    width: 100%;
    justify-content: right;
    position: absolute;
    padding: 0 15px;
    z-index: 20002; 
    pointer-events: none;
`


export const SchaduleScrollButtomOne = styled.div`
    display: flex;
    width: 100%;
    justify-content: space-between;
`

export const SchaduleScrollButtomTwo = styled.div`
    display: flex;
    
`

export const ScrollButton = styled.button`
  background: rgba(255, 255, 255, 0.8);
  border: none;
  width: 50px;
  height: 50px;
  padding: 5px;
  border-radius: 60px;
  cursor: pointer;
  font-size: 18px;
  pointer-events: auto;
  color: #000;
  &:hover {
    background: rgba(46, 0, 17, 0.8);
    color: #fff;
  }
`;