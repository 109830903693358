import styled from 'styled-components';


export const WrapperCulinaria = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    background: #964F39;
    z-index: 5;
    height: 100vh;

    @media screen and (max-width: 768px) {
        height: auto;
    }
`
export const TitleCulinaria = styled.h2`
    font-size: 28px;
    color: white;
    text-align: center;
    text-transform: uppercase;
    margin-bottom: 20px;
    margin-top: 40px;
    overflow: hidden;
    opacity: 0;

`

export const TitleSeparador = styled.div`
    width: 20%;
    height: 9px;
    background: white;
    overflow-y: hidden;
    opacity: 0;
`


export const WrapperItems = styled.div`
    display: flex;
    align-items: center;
    overflow: hidden;

    
    @media screen and (max-width: 768px) {
        flex-direction: column;
        height: 100%;
        
        }
        `
export const ItemCulinaria = styled.div`
    background: rgba(255 255 255 / 35%);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;

    max-width: 290px;
    height: auto;
    margin: 35px;
    border: solid 2px #FFF;
    padding: 60px 10px;
    border-radius: 30px;
    transform: scale(1);
    transition: all 0.5s ease-out;

    &:hover {
        transition: all 1s ease-out;
        transform: scale(1.085);
    }
`
export const Img = styled.img`
    width: 120px;
    height: auto;
    margin: -30px 0 40px 0;
`
export const TitleItem = styled.h3`
    color: white;
    font-size: 18px;
    margin-bottom: 40px;
    text-transform: uppercase;

`
export const TextItem = styled.p`
    color: white;
    text-align: center;
    text-justify: inter-character;
    font-size: 1rem;
    // text-align: start;

`