import { React, useLayoutEffect} from 'react'
import { Button } from '../ButtonElements';
import { useNavigate } from 'react-router-dom';

import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/all';

import photo from '../../images/cardapio_alpha.jpg';

import { 
    WrapperCardapio,
    FotoCardapio,
    PhotoCardapio,
    InfosCardapio,
    TitleCardapio,
 } from './CardapioElements';


const CardapioSection = () => {
    let nav = useNavigate();

    
    useLayoutEffect(() => {
        gsap.registerPlugin(ScrollTrigger);
        gsap.to("#cardapio", {
            opacity: 1,
            scrollTrigger:{
                trigger: "#markerTrigger",
                start: "top 90%",
                end: "bottom 80%",
                scrub: true,
            }
        })
        return() => {
            gsap.killTweensOf("#cardapio");
        }
    }, [])
  
  
    return (
    
    <>
        <WrapperCardapio id='cardapio'>
            <FotoCardapio>
                <PhotoCardapio src={photo} />
            </FotoCardapio>

            <InfosCardapio>
                <TitleCardapio id='markerTrigger'>Acesse aqui nosso Cardápio</TitleCardapio>
                <Button onClick={() => {nav("/cardapio")}}>Conhecer</Button>
            </InfosCardapio>
        </WrapperCardapio>
      
    </>
  )
}

export default CardapioSection
